
























































































































































import { Component } from 'vue-property-decorator';

import EvaluationDataTable from '@/components/evaluation/EvaluationDataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import { IItemSelect } from '@/model/util/IItemSelect';
import { internet } from '@/utils/Internet';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { IFilterEvaluation } from '@/model/evaluation/IFilterEvaluation';
@Component({
	name: 'MainEvaluation.vue',
	components: {
		EvaluationDataTable,
		AppContainerBase,
	},
})
export default class MainEvaluation extends MixinMain {
	public isLoading = false;
	public selectCustomer: Array<IItemSelect> = [];
	public selectCamping: Array<IItemSelect> = [];
	public selectAgent: Array<IItemSelect> = [];
	public selectTipoLlamada: Array<IItemSelect> = [];
	public listaAudios: Array<any> = [];
	public isOpenDate = false;
	public selected: Array<string> = ['', '', '', ''];
	public disables: Array<boolean> = [true, true];
	public selected_camp = "";
	public disabled_camps = true;
	public selected_agente = "";
	public disabled_agente = true;
	public selected_tipoLlamada = "";
	public disabled_tiposLLamada = true;
	public selected_fechaIni = "";
	public selected_fechaFin = "";
	public isDateEnd = false;
	public isDateTo = false;
	public maxDate: string = new Date()
		.toISOString()
		.slice(0, 10);
	public rows: Array<IDataTable> = [];
	public header_audios: Array<IHeaderTable<IDataTable>> = [
		{
			text: "Ruta SFTP",
			value: "ruta_audio"
		},
		{
			text: "Nombre",
			value: "nombre_audio"
		},
		{
			text: "Fecha",
			value: "fecha"
		},
		{
			text: "TAS",
			value: "tas"
		},
		{
			text: "Acciones",
			value: "detalle"
		},
	];
	public detalle = false;
	public headers_detalle: Array<IHeaderTable<IDataTable>> = [
		{
			text: "Subcategoria",
			value: "subcategoria"
		},
		{
			text: "Cumple",
			value: "cumple"
		},
	];
	public rows_detalle: Array<IDataTable> = [];

	mounted() {
		this.selected_fechaIni = "2024-01-01";
		this.selected_fechaFin = this.maxDate;
		this.isLoading = true;
		internet
			.newRequest()
			.get('tunning/getCamps')
			.then((response) => {
				this.selectCamping.push({
					text: 'Seleccione...',
					value: '',
				});
				console.log(response);
				for (let item of response.data) {
					this.selectCamping.push({
						text: item,
						value: item,
					});
				}
				this.disabled_camps = false;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public loadAgent() {
		if (this.selected_camp !== '') {
			this.isLoading = true;
			internet
				.newRequest()
				.get(`tunning/getAgentes?FiltrFechDesde=${this.selected_fechaIni}&FiltrFechHasta=${this.selected_fechaFin}&Campania=${this.selected_camp}`)
				.then((response) => {
					this.selectAgent = [];
					this.selected_agente = "";
					this.selectTipoLlamada = [];
					this.selected_tipoLlamada = "";
					this.rows = [];
					this.selectAgent.push({
						text: 'Seleccione...',
						value: '',
					});
					console.log(response);
					for (let item of response.data) {
						this.selectAgent.push({
							text: `${item.agente} (${item.audios})`,
							value: item.agente,
						});
					}
					this.disabled_agente = false;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
	}

	public loadTipoLlamada() {
		if (this.selected_camp !== '' && this.selected_agente !== '') {
			this.isLoading = true;
			internet
				.newRequest()
				.get(`tunning/getTiposLlamada?FiltrFechDesde=${this.selected_fechaIni}&FiltrFechHasta=${this.selected_fechaFin}&Campania=${this.selected_camp}&Agente=${this.selected_agente}`)
				.then((response) => {
					this.selectTipoLlamada = [];
					this.selected_tipoLlamada = "";
					this.rows = [];
					this.selectTipoLlamada.push({
						text: 'Seleccione...',
						value: '',
					});
					console.log(response);
					for (let item of response.data) {
						this.selectTipoLlamada.push({
							text: `${item.tipo_llamada} (${item.audios})`,
							value: item.tipo_llamada,
						});
					}
					this.disabled_tiposLLamada = false;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
	}

	public loadAudios() {
		if (this.selected_camp !== '' && this.selected_agente !== '' && this.selected_tipoLlamada !== '') {
			this.isLoading = true;
			internet
				.newRequest()
				.get(`tunning/getAudios?FiltrFechDesde=${this.selected_fechaIni}&FiltrFechHasta=${this.selected_fechaFin}&Campania=${this.selected_camp}&Agente=${this.selected_agente}&TipoLlamada=${this.selected_tipoLlamada}`)
				.then((response) => {
					console.log(response);
					this.rows = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
	}

	// public sendTo() {
	// 	this.isLoading = true;
	// 	let error = false;
	// 	for (let value of this.selected) {
	// 		if (value === '') {
	// 			error = true;
	// 			break;
	// 		}
	// 	}
	// 	if (error) {
	// 		Util.showMessage(
	// 			this.$t("DataTable.warning") as string,
	// 			Icon.INFO,
	// 			this.$t("DataTable.cuidado") as string
	// 		);
	// 		this.isLoading = false;
	// 		return;
	// 	}
	// 	internet
	// 		.newRequest()
	// 		.get(
	// 			`separacion/FechaTranscripcion/${this.selected[2]}/${this.selected[3]}`
	// 		)
	// 		.then((response) => {
	// 			if (response.data.length > 0) {
	// 				Util.showMessage(
	// 					'Información cargada',
	// 					Icon.SUCCESS
	// 				);
	// 				const data: Array<ITranscripData> = [];
	// 				for (let item of response.data) {
	// 					data.push(item.response);
	// 				}
	// 				localStorage.setItem(
	// 					'filter',
	// 					JSON.stringify(data)
	// 				);
	// 				this.$router.push({
	// 					name: 'SelectTranscription',
	// 				});
	// 			} else {
	// 				Util.showMessage(
	// 					'No hay audios en los filtros especificados',
	// 					Icon.INFO
	// 				);
	// 			}
	// 		})
	// 		.catch(console.log)
	// 		.finally(() => {
	// 			this.isLoading = false;
	// 		});
	// }

	get headers(): Array<IHeaderTable<IDataTable>> {
		let output: Array<IHeaderTable<IDataTable>> = [];

		if (this.rows.length > 0) {
			for (let key of Object.keys(this.rows[0])) {
				let header = {
					text: key,
					value: key
				}
				output.push(header);
			}
		}
		return output;
	}

	public verDetalle(item: IDataTable) {
		console.log(item);
		this.rows_detalle = item.detalle;
		this.detalle = true;
	}

	public siguiente(item: IDataTable) {
		console.log(item);
		const filter: IFilterEvaluation = {
			customer: '',
			campaing: this.selected_camp,
			agent: this.selected_agente,
			callType: this.selected_tipoLlamada,
			subCategory: '',
			audio: item.nombre_audio,
			ruta: item.ruta_audio,
		};
		console.log(filter);

		this.$router.push({
			name: 'ResultEvaluation',
			query: {
				filter: JSON.stringify(filter),
			},
			params: {
				prev: 'buscar'
			},
		});
	}
}
