var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-container-base',{attrs:{"title":'Buscar archivo de audio'}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-select',{attrs:{"color":"","items":_vm.selectCamping,"loading":_vm.isLoading,"dense":"","disabled":_vm.disabled_camps,"label":"Seleccione campaña"},on:{"change":_vm.loadAgent},model:{value:(_vm.selected_camp),callback:function ($$v) {_vm.selected_camp=$$v},expression:"selected_camp"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.isDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Desde","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.selected_fechaIni),callback:function ($$v) {_vm.selected_fechaIni=$$v},expression:"selected_fechaIni"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isDateTo),callback:function ($$v) {_vm.isDateTo=$$v},expression:"isDateTo"}},[_c('v-date-picker',{attrs:{"color":"#06B7B2","max":_vm.selected_fechaFin},on:{"input":function($event){_vm.isDateTo = false}},model:{value:(_vm.selected_fechaIni),callback:function ($$v) {_vm.selected_fechaIni=$$v},expression:"selected_fechaIni"}})],1)],1),_c('v-col',{attrs:{"xs":"12","sm":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.isDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hasta","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.selected_fechaFin),callback:function ($$v) {_vm.selected_fechaFin=$$v},expression:"selected_fechaFin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isDateEnd),callback:function ($$v) {_vm.isDateEnd=$$v},expression:"isDateEnd"}},[_c('v-date-picker',{attrs:{"color":"#06B7B2","max":_vm.maxDate,"min":_vm.selected_fechaIni},on:{"input":function($event){_vm.isDateEnd = false}},model:{value:(_vm.selected_fechaFin),callback:function ($$v) {_vm.selected_fechaFin=$$v},expression:"selected_fechaFin"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-select',{attrs:{"color":"","disabled":_vm.disabled_agente,"items":_vm.selectAgent,"loading":_vm.isLoading,"dense":"","label":"Seleccione agente"},on:{"change":_vm.loadTipoLlamada},model:{value:(_vm.selected_agente),callback:function ($$v) {_vm.selected_agente=$$v},expression:"selected_agente"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-select',{attrs:{"color":"","disabled":_vm.disabled_tiposLLamada,"items":_vm.selectTipoLlamada,"loading":_vm.isLoading,"dense":"","label":"Seleccione tipo de llamada"},on:{"change":_vm.loadAudios},model:{value:(_vm.selected_tipoLlamada),callback:function ($$v) {_vm.selected_tipoLlamada=$$v},expression:"selected_tipoLlamada"}})],1)],1),_c('v-row',{staticClass:"text-center py"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"width":"100%","dense":"","headers":_vm.header_audios,"items":_vm.rows,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.ruta_audio",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ruta_audio.split('/').slice(3).join('/')))])]}},{key:"item.detalle",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-btn',{attrs:{"title":"DETALLE"},on:{"click":function($event){return _vm.verDetalle(item)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi mdi-eye-outline")])],1),_c('v-separator'),_c('v-btn',{attrs:{"title":"IR AL TUNNING"},on:{"click":function($event){return _vm.siguiente(item)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi mdi-arrow-right-thick ")])],1)],1)]}}])})],1)],1)],1)],1),_c('v-dialog',{model:{value:(_vm.detalle),callback:function ($$v) {_vm.detalle=$$v},expression:"detalle"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"width":"100%","dense":"","headers":_vm.headers_detalle,"items":_vm.rows_detalle,"loading":_vm.isLoading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }